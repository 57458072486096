import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['customerId', 'sessionId', 'eventId', 'asso']

    connect() {
        console.log('connecting event_controller .....')
    }

    filter_reservation() {
        const eventId = this.eventIdTarget.value
        const sessionId = this.sessionIdTarget.value
        const customerId = this.customerIdTarget.value
        const asso = this.assoTarget.value

        const url = `/events/${eventId}?session_id=${sessionId}&customer_id=${customerId}&asso=${asso}`

        fetch(url, {
            headers: {
                "Accept": "text/vnd.turbo-stream.html"
            }
        })
            .then(response => response.text())
            .then(html => {
                Turbo.renderStreamMessage(html);
            })
            .catch(error => console.error('Erreur:', error));
    }

}